import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Box, Container, Flex, Heading } from "theme-ui";
import ThemedMarkdown from "../components/themed-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

type Props = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

const MarkdownHeroPage = ({ data, location }: Props) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      seo={{
        title: frontmatter.title,
        description: frontmatter.seoDescription,
        pathname: location,
      }}
    >
      <Container my={5}>
        <Heading as="h1" variant="styles.h2" mb={4}>
          {frontmatter.h1}
        </Heading>
        {frontmatter.heroblock.map((block, i) => (
          <Flex key={i} sx={{ flexDirection: "column" }}>
            {!!block?.image && (
              <GatsbyImage
                sx={{ flex: "1 1 0", my: 4 }}
                image={getImage(block.image)}
                alt={block.alttext}
              />
            )}
            <ThemedMarkdown html={block.markdown} />
          </Flex>
        ))}
      </Container>
    </Layout>
  );
};

export default MarkdownHeroPage;

export const pageQuery = graphql`
  query MarkdownHeroPageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seoDescription
        title
        h1
        heroblock {
          image {
            childImageSharp {
              gatsbyImageData(quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
          markdown
        }
      }
    }
  }
`;
